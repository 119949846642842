import "./App.css";
import styled from "styled-components";

import logoImg from "./logo.png";
import YoutubeEmbed from "./YoutubeEmbed";

import backgroundBlackPng from "./background-blue.png";

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 960px;
  padding-bottom: 50px;
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
`;

const Logo = styled.img`
  width: 75px;
`;

const AppTitle = styled.div`
  color: white;
  margin-left: 25px;
  font-size: 32px;
`;
const StyledOneWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const StyledDescription = styled.div``;

const StyledQuestion = styled.div`
  color: #ec5050;
  margin-top: 55px;
  margin-bottom: 8px;
  font-size: 28px;
`;

const StyledBodyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url("${backgroundBlackPng}");
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
`;

const StyledHref = styled.a`
  color: white;
  margin-bottom: 5px;
`;

function App() {
  return (
    <StyledBodyWrapper>
      <StyledWrapper>
        <LogoWrapper>
          <Logo src={logoImg} alt="Neural Art Logo" />
          <AppTitle>NEURAL ART</AppTitle>
        </LogoWrapper>
        <StyledOneWrapper>
          <StyledQuestion>What is it?</StyledQuestion>
          <StyledDescription>
            An interface for generating art images from neural data compatible
            with Emotiv Epoch X 14 channels headset.
          </StyledDescription>
        </StyledOneWrapper>
        <StyledOneWrapper>
          <StyledQuestion>How it works?</StyledQuestion>
          <StyledDescription>
            It leverages a fine tuned Stable Diffusion model that aligns EEG
            data with ART images.
          </StyledDescription>
        </StyledOneWrapper>
        <StyledOneWrapper>
          <StyledQuestion>Demo</StyledQuestion>
          <YoutubeEmbed embedId="8v_EB73m6cQ" />
        </StyledOneWrapper>
        <StyledOneWrapper
          style={{
            marginBottom: 50,
          }}
        >
          <StyledQuestion>Others</StyledQuestion>
          <StyledHref href="https://github.com/bobergsatoko/neural-art">
            GitHub page
          </StyledHref>
          <StyledHref href="https://github.com/bbaaii/DreamDiffusion">
            Dream Diffusion - aknowledgments for AI architecture and code
          </StyledHref>
          <StyledHref href="https://github.com/sai-42">
            Art Images - sai42
          </StyledHref>
        </StyledOneWrapper>
      </StyledWrapper>
    </StyledBodyWrapper>
  );
}

export default App;
